<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="question_content">
            <el-card shadow="always">
              <div class="import_content">
                <el-button
                  class="btn_import"
                  size="small"
                  type="danger"
                  @click="importSelector"
                  :disabled="true"
                  >{{ $t("question.btn_importquestion") }}</el-button
                >
              </div>
              <el-tabs
                v-model="pageData.mainTabsActName"
                @tab-click="mainTabsClick"
                type="card"
              >
                <el-tab-pane
                  :label="$t('question.tab_selector')"
                  name="selector"
                >
                  <div class="selector_top_content">
                    <span class="countQuestion"
                      >{{ pageData.selectorList.length
                      }}{{ $t("question.span_countquestion") }}</span
                    >
                    <el-button
                      size="small"
                      type="primary"
                      @click="addSelector"
                      >{{ $t("question.btn_addquestion") }}</el-button
                    >
                    <el-dialog
                      width="30%"
                      :title="$t('question.title_add_question')"
                      v-model="pageData.selectorAdderVisible"
                      ref="selectorAdder"
                      :modal="true"
                      :close-on-click-modal="false"
                      :close-on-press-escape="false"
                      :center="true"
                    >
                      <el-form
                        :model="formData.newSelector"
                        ref="newSelectorForm"
                        class="newSelectorForm"
                        size="mini"
                      >
                        <el-form-item :label="$t('question.label_number')">
                          <el-input
                            v-model="formData.newSelector.number"
                            maxlength="5"
                            show-word-limit
                            size="medium"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_target')">
                          <el-select
                            v-model="formData.newSelector.target_id"
                            filterable
                            size="small"
                            style="width: 100%"
                            :placeholder="$t('text.placeholder_select')"
                          >
                            <el-option
                              v-for="(
                                item, index
                              ) in pageData.questionTargetList"
                              :key="index"
                              :label="item.name_show"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_content')">
                          <el-input
                            v-model="formData.newSelector.content"
                            maxlength="250"
                            show-word-limit
                            size="medium"
                            type="textarea"
                            :rows="3"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_content_en')">
                          <el-input
                            v-model="formData.newSelector.content_en"
                            maxlength="250"
                            show-word-limit
                            size="medium"
                            type="textarea"
                            :rows="3"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>
                      </el-form>
                      <template #footer>
                        <span>
                          <el-button
                            class="addQuestionBtn"
                            type="success"
                            size="medium"
                            @click="doAddQuestion(formData.newSelector)"
                            :loading="pageData.doAddQuestionLoading"
                            >{{ $t("question.btn_save") }}</el-button
                          >
                        </span>
                      </template>
                    </el-dialog>
                  </div>
                  <div class="selector_list_content">
                    <el-table
                      stripe
                      border
                      ref="selectorTable"
                      :header-cell-style="{
                        textAlign: 'center',
                        backgroundColor: '#f2f6fc',
                      }"
                      :cell-style="{ textAlign: 'center' }"
                      :data="pageData.selectorList"
                    >
                      <el-table-column
                        prop="number"
                        :label="$t('question.label_number')"
                      >
                      </el-table-column>
                      <el-table-column :label="$t('question.label_target')">
                        <template #default="scope">
                          <span v-if="pageData.lang == 'ch'">{{
                            scope.row.target_name
                          }}</span>

                          <span v-else-if="pageData.lang == 'en'">
                            {{ scope.row.target_name_en }}</span
                          >
                          <span v-else> {{ scope.row.target_name_en }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_content')">
                        <template #default="scope">
                          {{ scope.row.content }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_content_en')">
                        <template #default="scope">
                          {{ scope.row.content_en }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_isused')">
                        <template #default="scope">
                          <span
                            style="color: #f56c6c"
                            v-if="scope.row.isUsed > 0"
                            >{{$t('text.inused')}}</span
                          >
                          <span style="color: #67c23a" v-else>{{$t('text.notused')}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_options')">
                        <template #default="scope">
                          <el-button
                            circle
                            class="el-icon-edit"
                            type="primary"
                            size="small"
                            @click="openSelectorReviser(scope.row)"
                          ></el-button>

                          <el-popconfirm
                            :title="
                              $t('question.message_delete_question_confirm')
                            "
                            @confirm="deleteQuestion(scope.row)"
                          >
                            <template #reference>
                              <el-button
                                circle
                                class="el-icon-delete"
                                type="danger"
                                size="small"
                              ></el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-dialog
                      width="30%"
                      :title="$t('question.title_revise_question')"
                      v-model="pageData.selectorReviserVisible"
                      ref="selectorReviser"
                      :modal="true"
                      :close-on-click-modal="false"
                      :close-on-press-escape="false"
                      :center="true"
                    >
                      <el-form
                        :model="formData.editSelector"
                        ref="editSelectorForm"
                        class="editSelectorForm"
                        size="mini"
                      >
                        <el-form-item :label="$t('question.label_number')">
                          <el-input
                            v-model="formData.editSelector.number"
                            maxlength="5"
                            show-word-limit
                            :disabled="true"
                            size="medium"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_target')">
                          <el-select
                            v-model="formData.editSelector.target_id"
                            filterable
                            size="small"
                            style="width: 100%"
                            :placeholder="$t('text.placeholder_select')"
                          >
                            <el-option
                              v-for="(
                                item, index
                              ) in pageData.questionTargetList"
                              :key="index"
                              :label="item.name_show"
                              :value="item.id"
                              v-show="item.isdel == '1'"
                            ></el-option>
                          </el-select>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_content')">
                          <el-input
                            v-model="formData.editSelector.content"
                            maxlength="250"
                            show-word-limit
                            size="medium"
                            type="textarea"
                            :rows="3"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_content_en')">
                          <el-input
                            v-model="formData.editSelector.content_en"
                            maxlength="250"
                            show-word-limit
                            size="medium"
                            type="textarea"
                            :rows="3"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>
                      </el-form>
                      <template #footer>
                        <span>
                          <el-button
                            class="reviseQuestionBtn"
                            type="success"
                            size="medium"
                            @click="doReviseSelector(formData.editSelector)"
                            :loading="pageData.doReviseSelectorLoading"
                            >{{ $t("question.btn_save") }}</el-button
                          >
                        </span>
                      </template>
                    </el-dialog>
                  </div>
                </el-tab-pane>
                <el-tab-pane
                  :label="$t('question.tab_subjective')"
                  name="subjective"
                >
                  <div class="subjective_top_content">
                    <span class="countQuestion"
                      >{{ pageData.subjectiveList.length
                      }}{{ $t("question.span_countquestion") }}</span
                    >
                    <el-button
                      size="small"
                      type="primary"
                      @click="addSubjective"
                      >{{ $t("question.btn_addquestion") }}</el-button
                    >
                    <el-dialog
                      width="30%"
                      :title="$t('question.title_add_question')"
                      v-model="pageData.subjectiveAdderVisible"
                      ref="subjectiveAdder"
                      :modal="true"
                      :close-on-click-modal="false"
                      :close-on-press-escape="false"
                      :center="true"
                    >
                      <el-form
                        :model="formData.newSubjective"
                        ref="newSubjectiveForm"
                        class="newSubjectiveForm"
                        size="mini"
                      >
                        <el-form-item :label="$t('question.label_number')">
                          <el-input
                            v-model="formData.newSubjective.number"
                            maxlength="5"
                            show-word-limit
                            size="medium"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_target')">
                          <el-select
                            v-model="formData.newSubjective.target_id"
                            filterable
                            size="small"
                            style="width: 100%"
                            :placeholder="$t('text.placeholder_select')"
                          >
                            <el-option
                              v-for="(
                                item, index
                              ) in pageData.questionTargetList"
                              :key="index"
                              :label="item.name_show"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_content')">
                          <el-input
                            v-model="formData.newSubjective.content"
                            maxlength="250"
                            show-word-limit
                            size="medium"
                            type="textarea"
                            :rows="3"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_content_en')">
                          <el-input
                            v-model="formData.newSubjective.content_en"
                            maxlength="250"
                            show-word-limit
                            size="medium"
                            type="textarea"
                            :rows="3"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>
                      </el-form>
                      <template #footer>
                        <span>
                          <el-button
                            class="addQuestionBtn"
                            type="success"
                            size="medium"
                            @click="doAddQuestion(formData.newSubjective)"
                            :loading="pageData.doAddQuestionLoading"
                            >{{ $t("question.btn_save") }}</el-button
                          >
                        </span>
                      </template>
                    </el-dialog>
                  </div>
                  <div class="subjective_list_content">
                    <el-table
                      stripe
                      border
                      ref="subjectiveTable"
                      :header-cell-style="{
                        textAlign: 'center',
                        backgroundColor: '#f2f6fc',
                      }"
                      :cell-style="{ textAlign: 'center' }"
                      :data="pageData.subjectiveList"
                    >
                      <el-table-column
                        prop="number"
                        :label="$t('question.label_number')"
                      >
                      </el-table-column>
                      <el-table-column :label="$t('question.label_target')">
                        <template #default="scope">
                          <span v-if="pageData.lang == 'ch'">{{
                            scope.row.target_name
                          }}</span>

                          <span v-else-if="pageData.lang == 'en'">
                            {{ scope.row.target_name_en }}</span
                          >
                          <span v-else> {{ scope.row.target_name_en }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_content')">
                        <template #default="scope">
                          {{ scope.row.content }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_content_en')">
                        <template #default="scope">
                          {{ scope.row.content_en }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_options')">
                        <template #default="scope">
                          <el-button
                            circle
                            class="el-icon-edit"
                            type="primary"
                            size="small"
                            @click="openSubjectiveReviser(scope.row)"
                          ></el-button>
                          <el-popconfirm
                            :title="$t('text.message_delete_confirm')"
                            @confirm="deleteQuestion(scope.row)"
                          >
                            <template #reference>
                              <el-button
                                circle
                                class="el-icon-delete"
                                type="danger"
                                size="small"
                              ></el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-dialog
                      width="30%"
                      :title="$t('question.title_revise_question')"
                      v-model="pageData.subjectiveReviserVisible"
                      ref="subjectiveReviser"
                      :modal="true"
                      :close-on-click-modal="false"
                      :close-on-press-escape="false"
                      :center="true"
                    >
                      <el-form
                        :model="formData.editSubjective"
                        ref="editSubjectiveForm"
                        class="editSubjectiveForm"
                        size="mini"
                      >
                        <el-form-item :label="$t('question.label_number')">
                          <el-input
                            v-model="formData.editSubjective.number"
                            maxlength="5"
                            show-word-limit
                            :disabled="true"
                            size="medium"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_target')">
                          <el-select
                            v-model="formData.editSubjective.target_id"
                            filterable
                            size="small"
                            style="width: 100%"
                            :placeholder="$t('text.placeholder_select')"
                          >
                            <el-option
                              v-for="(
                                item, index
                              ) in pageData.questionTargetList"
                              :key="index"
                              :label="item.name_show"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_content')">
                          <el-input
                            v-model="formData.editSubjective.content"
                            maxlength="250"
                            show-word-limit
                            size="medium"
                            type="textarea"
                            :rows="3"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('question.label_content_en')">
                          <el-input
                            v-model="formData.editSubjective.content_en"
                            maxlength="250"
                            show-word-limit
                            size="medium"
                            type="textarea"
                            :rows="3"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>
                      </el-form>
                      <template #footer>
                        <span>
                          <el-button
                            class="reviseQuestionBtn"
                            type="success"
                            size="medium"
                            @click="doReviseSubjective(formData.editSubjective)"
                            :loading="pageData.doReviseSubjectiveLoading"
                            >{{ $t("question.btn_save") }}</el-button
                          >
                        </span>
                      </template>
                    </el-dialog>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        mainTabsActName: 'selector',
        selectorList: [],
        subjectiveList: [],
        questionTargetList: [],
        selectorAdderVisible: false,
        subjectiveAdderVisible: false,
        selectorReviserVisible: false,
        subjectiveReviserVisible: false,
        doAddQuestionLoading: false,
        doReviseSelectorLoading: false,
        doReviseSubjectiveLoading: false,
        oldSelector: '',
        oldSubjective: ''
      },
      formData: {
        newSelector: {
          number: null,
          type: 0,
          target_id: '',
          content: '',
          content_en: '',
          creator: '',
          reviser: ''
        },
        newSubjective: {
          number: null,
          type: 1,
          target_id: '',
          content: '',
          content_en: '',
          creator: '',
          reviser: ''
        },
        editSelector: {
          id: '',
          number: 0,
          target_id: '',
          content: '',
          content_en: '',
          reviser: ''
        },
        editSubjective: {
          id: '',
          number: 0,
          target_id: '',
          content: '',
          content_en: '',
          reviser: ''
        }
      }
    };
  },
  mounted() {
    // document.title = this.$t('title.');
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');
    if (mountederjs.evaluateM(this)) {
      this.getQuestionTarget();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    mainTabsClick(tab, event) {
      //   console.log(tab, event);
    },
    getQuestions() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryQuestions',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            for (let question of response.data.data) {
              if (question.type === 0) {
                this.pageData.selectorList.push(question);
              } else {
                this.pageData.subjectiveList.push(question);
              }
            }
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('question.notify_questionnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getQuestionTarget() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryQuestionTarget',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.questionTargetList = response.data.data;

            for (let target of this.pageData.questionTargetList) {
              if (sessionStorage.getItem('lang') == 'en') {
                target.name_show = target.name_en;
              } else if (sessionStorage.getItem('lang') == 'ch') {
                target.name_show = target.name;
              } else {
                //roll to english
                target.name_show = target.name_en;
              }
            }

            this.getQuestions();

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('question.notify_questiontargetnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    importSelector() {
      this.$notify.error({
        title: this.$t("notify.title_notification"),
        message: this.$t('common.notify_import')
      });
    },
    addSelector() {
      this.pageData.selectorAdderVisible = true;
    },
    addSubjective() {
      this.pageData.subjectiveAdderVisible = true;
    },
    doAddQuestion(question) {
      // for (let index in question) {
      //   if (utilsjs.strIsEmpty(question[index])) {
      // this.pageData.doAddQuestionLoading = false;
      //     this.$notify.error({
      //       title: this.$t("common.notity_title_message"),
      //       message: this.$t('question.notify_incompletely')
      //     });
      //     return;
      //   } else {
      //     continue;
      //   }
      // }
      this.pageData.doAddQuestionLoading = true;

      if (utilsjs.strIsEmpty(question.number) ||
        utilsjs.strIsEmpty(question.target_id) ||
        utilsjs.strIsEmpty(question.content) ||
        utilsjs.strIsEmpty(question.content_en)
      ) {
        this.pageData.doAddQuestionLoading = false;
        this.$notify.error({
          title: this.$t("common.notity_title_message"),
          message: this.$t('question.notify_incompletely')
        });
        return;
      }

      //set values
      question.creator = this.pageData.username;
      question.reviser = this.pageData.username;

      //post
      this.postToAddQuestion(question);
    },
    postToAddQuestion(question) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/createQuestion',
        data: question
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.doAddQuestionLoading = false;

            setTimeout(() => {
              location.reload();
            }, 800)
            return;
          } else {
            //code = 700 and more
            this.pageData.doAddQuestionLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doAddQuestionLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doAddQuestionLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    deleteQuestion(question) {
      question.reviser = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/deleteQuestion',
        data: question
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_deleted"),
              type: 'success'
            });

            if (question.type == 0) {
              for (let index in this.pageData.selectorList) {
                if (this.pageData.selectorList[index].id === question.id) {
                  this.pageData.selectorList.splice(index, 1);
                }
              }
            } else if (question.type == 1) {
              for (let index in this.pageData.subjectiveList) {
                if (this.pageData.subjectiveList[index].id === question.id) {
                  this.pageData.subjectiveList.splice(index, 1);
                }
              }
            } else {
              location.reload();
            }

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    openSelectorReviser(selector) {
      this.pageData.oldSelector = '';
      this.pageData.oldSelector = JSON.parse(JSON.stringify(selector));
      //set values
      this.formData.editSelector.id = selector.id;
      this.formData.editSelector.number = selector.number;
      this.formData.editSelector.target_id = selector.target_id;
      this.formData.editSelector.content = selector.content;
      this.formData.editSelector.content_en = selector.content_en;
      this.formData.editSelector.reviser = this.pageData.username;

      this.pageData.selectorReviserVisible = true;
    },
    openSubjectiveReviser(subjective) {
      this.pageData.oldSubjective = '';
      this.pageData.oldSubjective = JSON.parse(JSON.stringify(subjective));
      //set values
      this.formData.editSubjective.id = subjective.id;
      this.formData.editSubjective.number = subjective.number;
      this.formData.editSubjective.target_id = subjective.target_id;
      this.formData.editSubjective.content = subjective.content;
      this.formData.editSubjective.content_en = subjective.content_en;
      this.formData.editSubjective.reviser = this.pageData.username;

      this.pageData.subjectiveReviserVisible = true;
    },
    doReviseSubjective(subjective) {
      this.pageData.doReviseSubjectiveLoading = true;

      if (subjective.target_id !== this.pageData.oldSubjective.target_id ||
        subjective.content !== this.pageData.oldSubjective.content ||
        subjective.content_en !== this.pageData.oldSubjective.content_en
      ) {
        for (let index in subjective) {
          if (utilsjs.strIsEmpty(subjective[index])) {
            this.pageData.doReviseSubjectiveLoading = false;
            this.$notify.error({
              title: this.$t("common.notity_title_message"),
              message: this.$t('question.notify_incompletely')
            });
            return;
          } else {
            continue;
          }
        }
      } else {
        //no change
        this.pageData.doReviseSubjectiveLoading = false;
        this.pageData.subjectiveReviserVisible = false;

        this.$ElMessage.warning({
          message: this.$t("text.message_nochange"),
          type: 'success'
        });
        return;
      }

      //pass
      this.postToRevise(subjective, 'doReviseSubjectiveLoading');
    },
    doReviseSelector(selector) {
      this.pageData.doReviseSelectorLoading = true;

      if (selector.target_id !== this.pageData.oldSelector.target_id ||
        selector.content !== this.pageData.oldSelector.content ||
        selector.content_en !== this.pageData.oldSelector.content_en
      ) {
        for (let index in selector) {
          if (utilsjs.strIsEmpty(selector[index])) {
            this.pageData.doReviseSelectorLoading = false;
            this.$notify.error({
              title: this.$t("common.notity_title_message"),
              message: this.$t('question.notify_incompletely')
            });
            return;
          } else {
            continue;
          }
        }
      } else {
        //no change
        this.pageData.doReviseSelectorLoading = false;
        this.pageData.selectorReviserVisible = false;

        this.$ElMessage.warning({
          message: this.$t("text.message_nochange"),
          type: 'success'
        });
        return;
      }

      //pass
      this.postToRevise(selector, 'doReviseSelectorLoading');
    },
    postToRevise(question, loadingName) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/reviseQuestion',
        data: question
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData[loadingName] = false;

            setTimeout(() => {
              location.reload();
            }, 800)
            return;
          } else {
            //code = 700 and more
            this.pageData[loadingName] = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData[loadingName] = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData[loadingName] = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.question_content {
  margin-top: 10px;
  padding: 20px;
}
.import_content {
  text-align: right;
}
.selector_top_content .el-button,
.subjective_top_content .el-button {
  float: right;
  margin-bottom: 20px;
}
.countQuestion {
  float: left;
  margin-left: 20px;
  font-size: 14px;
  color: #f56c6c;
}
.addQuestionBtn,
.reviseQuestionBtn {
  margin-top: -30px;
  float: right;
}
</style>